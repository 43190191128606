<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10">
        <div class="heading">
          <div>{{ $t('what_are_raising_money') }}</div>
          <div class="hLine"></div>
        </div>
        <div class="paragraphMessage">
          <div v-html="getDetailMessage"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CampaignMessage",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign.campaign_detail;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
    getDetailMessage() {
      return this.getCampaignDetail.special_message;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.paragraphMessage {
  font-family: Lato;
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    float: right;
  }
}
</style>
